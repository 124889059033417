<script context="module">
  import { Router, createRouter } from "@roxi/routify";
  import routes from ".routify/routes.default.js";
  import ServiceWorker from "./ServiceWorker.svelte";
  import { updateGraphqlClient } from "@utils/graphql";
  import { session, clientConfig } from "@root/_store";

  /** @type {UrlRewrite}*/
  const urlRewrite = {
    toExternal: (url) => url,
    toInternal: (url) => {
      if (url && url.match("/beheer")) location.href = url; // Redirect to admin application
      if (url && url.match("/avatars")) location.href = url; // Redirect to assets
      if (url && url.match("/files")) location.href = url; // TODO, might not be needed avoid risk of direct url share
      if (url && url.match(/\?\%23=/)) return url.replace("?%23=", "#");
      return url;
    },
  };

  export const router = createRouter({ routes, name: "", urlRewrite });

</script>

<script>
  import { setContext } from "svelte";
  setContext('token', session.authorizationHeader );
  setContext('files_endpoint', clientConfig.get("FILES_URI") );
  updateGraphqlClient();
</script>

<svelte:head>
  <!-- Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
  <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>
</svelte:head>
<ServiceWorker />
<Router {router} {urlRewrite} />

