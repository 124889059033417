import { writable, readable, get, Writable } from "svelte/store";
import { Session, SessionData } from "@utils/Session";
import { getFeatures } from "@services/features";
import { PusherService } from "@services/pusher";
import client from "@config/client";

export const clientConfig = client.config(JSON.parse(localStorage.getItem("client")) || undefined)
export const installed = writable(false);
installed.subscribe((value)=> {
  if(value) console.log("Service worker installed", APP_VERSION)
})
export const loggedIn = () =>
  JSON.parse(localStorage.getItem("user"))?.token || undefined;
export const currentSession: Writable<SessionData> = writable(loggedIn());
export const env = readable(process.env.NODE_ENV);

export const allGroups = writable([])
export const allUsers = writable([])

let _newVersion;

function getVersion() {
	const { subscribe, set } = writable(null);

  return {
		subscribe,
    load: async () => {
      const version = await fetch('/latest-version.html');
      _newVersion = await version.text()
      set(_newVersion)
    },
		get: () => _newVersion
	};
}

export const newVersion = getVersion();

let _features;

function createFeatures() {
	const { subscribe, set } = writable(null);

  return {
		subscribe,
    load: async () => {
      _features = await getFeatures()
      set(_features)
    },
		get: () => _features
	};
}

export const features = createFeatures();

features.subscribe((features) => console.log("features", features));

export const statusTrigger = writable(false);

export const status = readable("polling", function start(set) {
  fetch(clientConfig.get("GRAPHQL_URI") + "/status", {
    method: "HEAD",
    headers: { Accept: "text/html" },
  }).then((res) => {    
    set("online");
  }).catch((res) => {
    set("offline");
  });
	const interval = setInterval(async () => {
    fetch(clientConfig.get("GRAPHQL_URI") + "/status", {
      method: "HEAD",
      headers: { Accept: "text/html" },
    }).then((res) => {
      set("online");
    }).catch((res) => {
      set("offline");
    });
	}, 10000);

	return function stop() {
		clearInterval(interval);
	};
});
export const session = new Session(currentSession);
export const notification: any = writable();
export const feedbackModal: any = writable(null);
export const notifications: any = writable([]);
export const pushService = new PusherService(session.userId);
export const pusher = pushService.getInstance();
export const membersLoaded: any = writable(false);

notification.subscribe((notification) =>{
  if(notification){
    notification.remove = (index) => {
      const list = get(notifications)
      if (index > -1){
        while(index > (list.length - 1)) index = index -1 // In case simultaneously hidden
        list.splice(index, 1);
        notifications.set(list)
      }
    } 
    notifications.set([...get(notifications), notification])
  }
})

clientConfig.subscribe((config) => {
  if(config.NOT_DEFAULT) {
    const newConfig = JSON.stringify({
      HOST: config.HOST
    })
    if (config != undefined && config?.HOST && localStorage.getItem("client") !== newConfig) {
      console.log("new host save to storage", clientConfig.get("GRAPHQL_URI"))
      // If config is set, update localStorage, but check availability first
      fetch(clientConfig.get("GRAPHQL_URI") + "/status", {
        method: "HEAD", // TODO: HEAD Request
        headers: { Accept: "text/html" },
      }).then((res) => {    
        localStorage.setItem("client", newConfig);

        console.log("New host set to: ", config.HOST, "reloading app");
        window.location.reload();
      }).catch((res) => {
        notification.set({ type: "danger", content: "De testserver lijkt niet bereikbaar, vernieuw de pagina om terug te gaan", time: 7000});
      });
      
    } else {
      console.log("Host is set to: ", config.HOST);
    }
  }else{
    localStorage.removeItem("client");
  }
});

currentSession.subscribe((val) => {
  if (val != undefined) {
    // If user is set, update localStorage
    localStorage.setItem("user", JSON.stringify(val));
  } else if (
    !localStorage.getItem("user") ||
    session.isValid ||
    session.expired ||
    (!session.isValid &&
      session.expired === false &&
      localStorage.getItem("user"))
  ) {
    // If user is undefined but session is still valid or if session expired, invalidate user
    localStorage.removeItem("user");
  } else if (
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user")).token &&
    JSON.parse(localStorage.getItem("user")).access_token
  ) {
    // If user and session are undefined but user is available in localStorage, update session with user
    const sessionData = JSON.parse(localStorage.getItem("user"));
    session.update(sessionData);
    session.save();
    console.log(
      `Session restored
          ${sessionData.user.username}
          and have the following roles:
          ${[...session.getEntitlements]}`
    );
  } else if (
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user")).token &&
    !JSON.parse(localStorage.getItem("user")).token.access_token
  ) {
    // Remember user for onboarding without acces token
    const sessionData = JSON.parse(localStorage.getItem("user"));
    session.update(sessionData);
    session.save();
    console.log(
      `Session restored without acces token (Onboarding)
          ${sessionData.user.username || sessionData.user.displayName}
          and have the following roles:
          ${[...session.getEntitlements]}`
    );
  } else if (
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")) &&
    !JSON.parse(localStorage.getItem("user")).token
  ) {
    // If user is available in localStorage but no token is present, invalidate user
    localStorage.removeItem("user");
  }
});

status.subscribe((pulse) => {
  if(pulse === "offline"){
    statusTrigger.set(false);
    notification.set({ type: "action", action: () => window.location.reload(), id: "offline", actionText: "Vernieuwen",  content: "<strong>Het lijkt erop dat we 🏠 Zwolle niet (meer) kunnen bereiken.</strong> </br> Dit kan vanwege onderhoud ⚒️,</br> of check je internetverbinding 🛜." })
  } else if(pulse === "online" && get(notifications).find(n => n.id === "offline")){
    notifications.set(get(notifications).filter(n => n.id != "offline"))
    notification.set({ type: "success", content: "We hebben weer verbinding 🎉" })
    statusTrigger.set(true);
  }
})