<script>
  import { useRegisterSW } from "virtual:pwa-register/svelte";
  import { notification, notifications, installed, newVersion, pushService, session } from "@root/_store";
  import { writable, get } from "svelte/store";
  import { onMount } from "svelte";
  import { login } from "@endpoints/login.gql";
  import { query } from "svelte-apollo";

  const fn = () => {};
  let offlineReady = writable(null);
  let needRefresh = writable(null);
  let updateServiceWorker = fn;

  console.log("Ichthus Visje versie:", APP_VERSION, `build: ${BUILD_DATE}, ${NODE_ENV}, ${SERVER_PORT}`);
  if (process.env.NODE_ENV === "local") {
    // Disable serviceworkers for local development
    console.warn("Serviceworker disabled for local development");
    if (navigator && navigator.serviceWorker) navigator.serviceWorker.register = () => new Promise(fn, fn);
  } else {
    // } else if ("serviceWorker" in navigator) {
    const sw = useRegisterSW({
      onRegistered(r) {
        if (RELOAD_SW) {
          let intervalMS = 60 * 60 * 1000; // Check for new serviceworker every 1 Hour
          if(RELOAD_SW === "test") intervalMS = 5000; 
          // If build with RELOAD_SW=true
          r &&
            setInterval(() => {
              console.log("Checking for sw update");
              r.update();
            }, intervalMS);
        } else {
          console.log(`SW Registered:`, r);
          if (!pushService.beamsClient) {
            console.log(`Service worker Beams:`, $session.userId);
            pushService.initBeams($session.userId, r);
          }
          installed.set(true);
        }
      },
      onRegisterError(error) {
        console.log("SW registration error", error);
      },
    });
    offlineReady = sw.offlineReady;
    needRefresh = sw.needRefresh;
    updateServiceWorker = sw.updateServiceWorker;
  }

  // $: prompt = $offlineReady || $needRefresh;

  // function close() {
  //   offlineReady.set(false);
  //   needRefresh.set(false);
  // }
  let deferredPrompt;
  onMount(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      deferredPrompt = event;

      notification.set({
        id: "installApp",
        // dismiss: false, // TODO: check if mobile or allow dismiss on desktop only
        type: "action",
        action: () => installApp(),
        actionText: "Installeren",
        content: `<strong>Installeer de app voor een betere ervaring! 🤩</strong> </br> Voeg het toe aan je hoofdscherm door te installeren.`,
      });
    });

    window.addEventListener("appinstalled", (event) => {
      console.log("appinstalled", event);
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt = null;
      notifications.set(get(notifications).filter((n) => n.id != "installApp"));
    });
  });

  const loginQuery = query(login, {waitForTrigger: true} );

  async function installApp() {
    console.log("installApp", deferredPrompt);
    if (!deferredPrompt) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    deferredPrompt.prompt();
    // Log the result
    const result = await deferredPrompt.userChoice;
    console.log("userChoice", result);
    //{outcome: "accepted",platform: "web"}

    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    deferredPrompt = null;
    notifications.set(get(notifications).filter((n) => n.id != "installApp"));
  }

  $: if ($needRefresh) {
    newVersion.load();
  }
  $: if ($needRefresh && $newVersion) {
    notification.set({
      dismiss: false,
      type: "action",
      action: async () => { // TODO: Reuse function from settings > "Opnieuw laden"
        return await loginQuery
        .refetch({ input: { user: $session.username } })
        .catch((err) => {
          const result = err.graphQLErrors[0]?.extensions;
          console.log("catch", result);
          if (result?.code === "I_USER_ONBOARDING") {
            console.log("reauth triggered, onboarding")
            $session.update(result);
            $session.save();
            if (location.pathname === "/onboarding") {
              console.log("updateServiceWorker")
              return updateServiceWorker(true);
            } else {
              window.location.pathname = "/onboarding";
              console.log("updateServiceWorker")
              return updateServiceWorker(true);
            }
          } else {
            console.log("reauth triggered, logging out")
            $session.invalidate();
            console.log("updateServiceWorker")
            return updateServiceWorker(true);
          }
        })
        .then((result) => {
          if (result) {
            console.log("reauth triggered, success")
            $session.update(result.data.login);
            $session.save();
            console.log("updateServiceWorker")
            return updateServiceWorker(true);
          }
        });
        
      },
      actionText: "Vernieuwen",
      content: `<strong>Nieuwe update! 🚀</strong> </br> Versie ${$newVersion} staat voor je klaar. Check de <a href="/updates">laatste updates</a>`,
    });
  }
  $: if ($offlineReady)
    notification.set({
      icon: "✅",
      title: `Update geinstalleerd v${APP_VERSION}`,
      content: `Je bent weer helemaal bij, bekijk hier de <a href="/updates">laatste updates</a>.`,
      time: 7000,
    });
</script>

<div class="build-date">{BUILD_DATE}</div>
<div class="build-version">{APP_VERSION}</div>

<style>
  span {
    text-decoration: none;
  }
  span.close {
    position: absolute;
    top: 0.5em;
    right: 0.3em;
    line-height: 10px;
  }
  .card {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    z-index: 9999;
    background: white;
    margin-bottom: 0 !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    border-radius: 0 4px 0 0;
  }
  p {
    margin: 16px 32px;
  }
  .build-date,
  .build-version {
    visibility: hidden;
    display: none;
  }
</style>
